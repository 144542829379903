import { reactive, onBeforeUnmount, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute,useRouter } from "vue-router";
import auctionApi from "@/api/auctionApi";
import shopApi from "@/api/shopApi";
import { Toast } from 'vant';


const detailModel=()=>{
    const route = useRoute();
    const options = reactive({
      imgs: [
        { pictureMiddle: "images/xq3.png", title: "" },
        { pictureMiddle: "images/xq3.png", title: "" },
      ],
      imgsDefault:[],
      visibilitychange:false,
      auctionId: route.params.id,
      item: {
        id: "8182171323981709312",
        title: "",
        describe: "<p></p>",
        goods: [],
        bondRule:{
          amount:0
        },
        startTime: new Date(),
        endTime: new Date(),
      },
      redPacketList:[],
      myRedPacket:[],
      goodsInfos:[],
      bids: [],
      isHandle: false,
      downOptions: {
        currentTime: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        delayedTime:0,
        delayedEnd:true,
        endMessage:'距离结束'
      },
      roomCount:0,
      bidCount:0,
      isShow: false,
      loading: false,
      finished: false,
      notifyShow: false,
      isRedShow:false,
      isVideoShow:false,
      videoUrl:'',
      isImgPreviewShow:false,
      aimgIndex:0,
      aimages:[],
      toast: {
        message: "",
      },
      nowAmount: 0,
      isBond:false,
      goods: {
        id: "",
      },
      shop:{
        id:null,
        goods:[]
      },
      loc: "m1",
      step:0,
      isMerchantId:0,
      isShop:0,
    });
    const store = useStore();
    const router = useRouter();
    options.nowAmount = computed(() => store.state.socket.nowBidAmount);
    options.item = computed(() => store.state.socket.item);
    options.bids = computed(() => store.state.socket.bids);
    options.downOptions = computed(() => store.state.socket.downOptions);
    options.goods = computed(() => store.state.socket.goods);
    options.imgs = computed(() => store.state.socket.imgs);
    options.aimages = computed(() => store.state.socket.aimages);
    options.imgsDefault = computed(() => store.state.socket.imgsDefault);
    options.goodsInfos = computed(() => store.state.socket.goodsInfos);
    options.redPacketList = computed(() => store.state.socket.redPacketList);
    options.myRedPacket = computed(() => store.state.socket.myRedPacket);
    options.roomCount = computed(() => store.state.socket.roomCount);
    options.bidCount = computed(() => store.state.socket.bidCount);
    console.log(JSON.stringify(options.imgsDefault));
    options.isHandle = computed(() => store.state.socket.isHandle);
    options.isBond = computed(() => store.state.socket.isBond);
    options.isShop = computed(() => store.state.socket.isShop);
    options.shop=computed(() => store.state.socket.shop);
    options.isMerchantId = computed(() => store.state.socket.isMerchantId);
    options.visibilitychange = computed(() => store.state.common.visibilitychange);
    watch(()=>options.auctionId,()=>{
        store.dispatch("Init", options.auctionId);
    })
    store.dispatch("Init", options.auctionId);
    //store.commit('setNoticeMessage', { isNoticeShow: true, close: true, title: "保证金", type: 'FUND', message: '亲，您账户余额不足请充值！' })
    const pramsBid = reactive({
      auctionId: "",
      auctionGoodsId: "",
      nowAmount: 0,
      bidNum: "1",
      amount: 0,
    });
    const bidNum = ref(1);
    function searchBid() {
      options.finished = true;
      options.loading = true;
    }
    
    const onAChange = (newIndex) => {
      options.aimgIndex = newIndex;
    };
    const openAPreview = (index) => {
      options.aimgIndex = index;
      options.isImgPreviewShow = true;
    };
    const payVideo = (url) => {
      options.videoUrl = url;
      options.isVideoShow = true;
    }
    const collectionAdd=()=>{
      shopApi.collectionAdd({shopId:options.shop.id}).then(res=>{
          if(res.status==200){
            Toast.success(options.shop.name+",收藏完成");
          }else{
            Toast.fail(res.message);
          }
      });
    }

    function send() {
      pramsBid.auctionId = options.auctionId;
      pramsBid.auctionGoodsId = options.goods.id;
      pramsBid.nowAmount = options.nowAmount;
      auctionApi.launch(pramsBid).then((res) => {
        if (res.status == 200) {
          console.log("bid launch");
          options.isShow = false;
          options.toast.message = "sssss";
          store.commit('setNoticeMessage',{isNoticeShow:true,close:false,title:'处理消息',type:'START',message:"已提交,正在处理中......."})
        }else if(res.status == 307){
          router.push({ path: '/pay/BOND/'+options.auctionId });
        }else{
          options.isShow = false;
          store.commit('setNoticeMessage',{isNoticeShow:true,close:false,title:'处理消息',type:'START',message:res.message})
        }
      });
    }
    onBeforeUnmount(() => {
      store.commit("close");
    });
    function scrollInto(loc) {
      options.loc = loc;
      let toElement = document.getElementById(loc);
      toElement.scrollIntoView();
    }
    function showBid() {
      console.log("show");
      changeBidNum();
      if(options.item.type=='FIXED'){
        send();
      }else{
        options.isShow = true;
      }
     
    }
    function downScb(x) {
      //options.isHandle=true;
      store.commit("setHandle", true);
      console.log("star time :"+x);
    }
    function downEcb(x) {
      //options.isHandle=false;
      store.commit("setHandle", false);
      console.log(x);
    }
    function changeBidNum() {
      pramsBid.amount =
        options.nowAmount + options.goods.bidAmount * parseInt(pramsBid.bidNum);
    }
    return {
      options,
      pramsBid,
      bidNum,
      send,
      downScb,
      downEcb,
      showBid,
      searchBid,
      changeBidNum,
      scrollInto,
      payVideo,
      onAChange,
      openAPreview,
      collectionAdd,
    };
}

export default detailModel