<template>
  <div class="hdbox">
    <div class="hdbox0 snap-head">
      <count-down @start_callback="downScb(1)" @end_callback="downEcb(1)" :currentTime="options.downOptions.currentTime"
        :startTime="options.downOptions.startTime" :endTime="options.downOptions.endTime"
        :delayedEnd="options.downOptions.delayedEnd" :tipText="'距离开始'" :tipTextEnd="options.downOptions.endMessage"
        :endText="'已结束'" :dayTxt="'天'" :hourTxt="'时'" :minutesTxt="'分'" :secondsTxt="'秒'"></count-down>
    </div>
  </div>
  <div class="hbox"></div>
  <a id="m1">
    <div class="xqbox1">
      <van-swipe :autoplay="3000" lazy-render style="height: 50vh">
        <van-swipe-item v-for="(image, index) in options.imgs" :key="image">
          <img :src="image.pictureMiddle" style="width: 100%; object-fit: cover" @click="openAPreview(index)" />
          <span style="
              position: fixed;
              z-index: 100000;
              right: -2rem;
              top: 16rem;
              width: 6rem;
            " v-if="image.isVideo == 1" @click="payVideo(image.pictureBig)"><van-icon size="3rem" color="#1989fa"
              name="play-circle-o" /></span>
        </van-swipe-item>
      </van-swipe>
      <p class="tit">{{ options.item.title }}</p>
      <div class="qita" v-if="
        options.item.redPacket != null &&
        options.item.redPacket.isAvailable == 1
      ">
        <p1 class="p1" v-if="options.item.type == 'FIXED'">红包：<van-icon name="bill-o" />{{
          stringUtils.getRedPacketAmount(
            options.item.redPacket.amountRatio,
            options.goods.startPrice,
            1,
            "FIXED"
          )
        }}</p1>
        <p1 class="p1" v-else>红包：<van-icon name="bill-o" />{{
          stringUtils.getRedPacketAmount(
            options.item.redPacket.amountRatio,
            options.nowAmount == 0
              ? options.goods.bidAmount
              : options.nowAmount,
            options.bidCount == 0 ? 1 : options.bidCount,
            "DEFAULT"
          )
        }}元/个</p1>
        <p1 class="p1" v-if="
          options.item.type != 'FIXED' && options.redPacketList.length > 0
        ">红包总金额：￥{{
            options.redPacketList.length > 0
              ? stringUtils.fixedFormat(options.redPacketList[0].allRedAmount)
              : "0.00"
          }}</p1>
      </div>
      <div class="qita" @click="options.isRedShow = true"
        v-if="options.item.type != 'FIXED' && options.redPacketList.length > 0">
        <p class="p1" style="width: 43%">
          <span>第一：{{ options.redPacketList[0].memberName }} ￥{{
            stringUtils.fixedFormat(options.redPacketList[0].amount)
          }}</span>
        </p>
        <p class="p2" style="float: left" v-if="options.myRedPacket.length > 0">
          我的￥{{ stringUtils.fixedFormat(options.myRedPacket[0].amount) }}
        </p>
        <p class="p2" style="float: left; width: 43%" v-else>我的￥0.00</p>
        <img src="images/more.png" style="
            float: right;
            position: absolute;
            width: 1rem;
            margin-top: 0.29rem;
          " />
      </div>
    </div>
  </a>

  <!-- <div class="xqbox2">
  <div class="xqbox2L">
    <img src="images/my3.png" />
    <span>领取优惠券</span>
  </div>
  <div class="xqbox2R">
    <img src="images/more.png">
  </div>
</div> -->
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="xqdp" v-bind="$attrs">
    <div class="xqdp_1">
      <div class="xqdp1_1">
        <div class="dpimg">
          <van-image width="48" height="48" style="width: 100%; object-fit: cover" :src="options.goods.goodsPicture" />
        </div>
        <div class="dptxt">
          <p class="p1">{{ options.goods.goodsName }}</p>
          <p class="p2" v-if="options.item.type == 'FIXED'">
            <code>在线：{{ options.roomCount }}人 </code>
          </p>
          <p class="p2" v-else>
            <code>在线:{{ options.roomCount }}人 </code>
            <code>出价:{{ options.bidCount }}次 </code>
            <code style="width: 4rem" v-if="
              options.item.delayedRule != null &&
              options.item.delayedRule.isAvailable == 1
            ">延时：{{ options.item.delayedRule.delayTime }}秒</code>
          </p>
        </div>
      </div>
      <div class="xqdp1_2">
        <div style="
            float: left;
            width: 48%;
            border: 1px solid #4c9108;
            border-radius: 3px;
            height: 2.3rem;
            margin-right: 0.1rem;
          ">
          <div style="
              margin-right: 0.6rem;
              margin-top: 0.3rem;
              font: 0.5em sans-serif;
            " v-if="options.goods.isMail == 1">
            <code style="text-align: center; color: #4c9108; margin-left: 0.8rem">邮费</code>
            <span style="color: #4c9108">￥{{ stringUtils.fixedFormat(options.goods.mailPrice) }}</span>
          </div>
          <div style="font: 0.5em sans-serif" v-else>
            <img src="images/car.png" style="
                display: block;
                margin: 0 auto;
                height: 1rem;
                margin-top: 0.2rem;
              " />
            <span style="
                display: block;
                color: #4c9108;
                text-align: center;
                font-size: 0.5rem;
                line-height: 1rem;
                -webkit-text-size-adjust: none;
              ">包邮</span>
          </div>
        </div>
        <div class="scbox" style="float: left; width: 45%" @click="share">
          <img src="images/dpic.png" />
          <span>分享</span>
        </div>
      </div>
    </div>

    <div class="dpdengji">
      <ul>
        <li>
          <div class="v1" v-if="options.item.type == 'FIXED'">
            一口价<span>￥{{ stringUtils.fixedFormat(options.goods.startPrice) }}</span>
          </div>
          <div class="v1" v-else>
            起拍价<span>￥{{ stringUtils.fixedFormat(options.goods.startPrice) }}</span>
          </div>
        </li>
        <li v-if="options.item.type != 'FIXED'">
          <div class="v1">
            加价金额<span>￥{{ stringUtils.fixedFormat(options.goods.bidAmount) }}</span>
          </div>
        </li>
        <li v-if="options.item.type != 'FIXED'">
          <div class="v1">
            当前出价<span>￥{{ stringUtils.fixedFormat(options.nowAmount) }}</span>
          </div>
          <div class="v2">新</div>
        </li>
      </ul>
    </div>
    <van-list v-model:loading="options.loading" :finished="options.finished" @load="searchBid">
      <!-- <van-cell v-for="item in options.bids" :key="item" :title="item.name" /> -->
      <div class="van-clearfix" v-for="(item, index) in options.bids" :key="item">
        <div class="float-item">{{ item.memberName }}</div>
        <div class="float-item">
          ￥{{ stringUtils.fixedFormat(item.amount) }}
        </div>
        <div class="float-item" v-if="options.item.status == 6">
          <van-tag v-if="index == 0" type="warning">成交</van-tag>
          <van-tag v-else>失败</van-tag>
        </div>
        <div class="float-item" v-else>
          <van-tag style="" v-if="index == 0" type="danger">领先</van-tag>
          <van-tag v-else>被超越</van-tag>
        </div>
      </div>
    </van-list>
  </div>
  <div class="xqdp" v-if="options.isShop == 1">
    <div class="xqdp_1">
      <div class="xqdp1_1">
        <div class="dpimg"><img :src="options.shop.logo" /></div>
        <div class="dptxt">
          <p class="p1">{{ options.shop.title }}</p>
          <p class="p2">
            总拍卖：{{
              options.shop.auctionNum == null ? 0 : options.shop.auctionNum
            }}
            收藏数：{{
              options.shop.collectionNum == null
                ? 0
                : options.shop.collectionNum
            }}
          </p>
        </div>
      </div>
      <div class="xqdp1_2">
        <div class="scbox" @click="collectionAdd">
          <img src="images/xq4.png" />
          <span>收藏店铺</span>
        </div>
      </div>
    </div>
    <div class="tjcon">
      <ul>
        <li v-for="(g, index) in options.shop.goods" :key="g.id">
          <router-link v-if="index < 4" :to="'/auctionDetail/' + g.id">
            <van-image width="5rem" height="5rem" style="object-fit: cover" :src="g.picture_small" />
            <div class="tit">
              <span>{{ g.title }}</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="xqbtn">
      <!-- <a href="dqxq2.html">全部拍卖(78)</a> -->
      <router-link style="margin-left: 8.5rem" :to="'/shop/' + options.shop.id">进店逛逛</router-link>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="xgtj" style="display: none" id="m3">
    <div class="tjtit">相关推荐</div>
    <div class="tjcon">
      <ul>
        <li>
          <a href="xq.html">
            <img src="images/xq2.png" />
            <div class="tit"><span>￥88.8</span></div>
          </a>
        </li>
        <li>
          <a href="xq.html">
            <img src="images/xq2.png" />
            <div class="tit"><span>￥88.8</span></div>
          </a>
        </li>
        <li>
          <a href="xq.html">
            <img src="images/xq2.png" />
            <div class="tit"><span>￥88.8</span></div>
          </a>
        </li>
        <li>
          <a href="xq.html">
            <img src="images/xq2.png" />
            <div class="tit"><span>￥88.8</span></div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="xqtab" style="margin-bottom: 1rem" v-show="options.item.synopsis != '' && options.item.synopsis != null">
    <div class="Menubox">
      <ul>
        <li class="hover">拍品介绍</li>
        <!-- <li class=""  id="two3">热卖推荐</li> -->
      </ul>
    </div>
    <div class="Contentbox">
      <div id="con_two_1">
        <div class="xqsub" style="
            min-height: 5rem;
            line-height: 1.2rem;
            text-indent: 20px;
            margin-top: 0.5rem;
            padding: 0.1rem 0.1rem 0.1rem 0.1rem;
            background: #fff;
          ">
          {{ options.item.synopsis }}
        </div>
        <div class="likebox bort">
          <ul>
            <li v-for="img in options.imgsDefault" :key="img.id">
              <a href="javascript:;">
                <van-image width="10rem" height="12rem" class="proimg" style="object-fit: cover; margin-left: 0.35rem"
                  :src="img.pictureMiddle" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="xqtab" v-show="options.goodsInfos != null && options.goodsInfos.length > 0">
    <div class="Menubox">
      <ul>
        <li class="hover">拍品参数</li>
        <!-- <li class=""  id="two3">热卖推荐</li> -->
      </ul>
    </div>
    <div class="Contentbox">
      <div id="con_two_2" style="margin-bottom: 2.6rem">
        <div class="canshu" v-for="info in options.goodsInfos" :key="info.id">
          <div class="canshu_1">
            <p class="pl" style="text-align: right; overflow: hidden">
              {{ info.key }}
            </p>
            <p class="pr" style="padding-left: 0.5rem; overflow: hidden">
              {{ info.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div style="height: 3rem"></div>
  <to-top></to-top>
  <div class="xqbotbox" v-bind="$attrs">
    <div class="xqbotbox0">
      <div class="xqbotboxL">
        <ul>
          <li>
            <router-link to="/">
              <van-icon name="wap-home-o" size="1.8rem" />
              <p>首页</p>
            </router-link>
          </li>
          <li>
            <router-link :to="'/auctionList'">
              <van-icon name="coupon-o" size="1.8rem" />
              <p>列表</p>
            </router-link>
          </li>
          <li>
            <router-link :to="'/order'">
              <van-icon name="todo-list-o" size="1.8rem" />
              <p>订单</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="xqbotboxR" v-if="
        options.isBond &&
        options.isMerchantId == 0 &&
        options.item.status == 2
      ">
        <router-link class="a2 sizetype" style="
            background-color: darkorange;
            border: 1px solid darkorange;
            width: 8.5rem;
          " :to="'/pay/BOND/' + options.auctionId">保证金:￥{{
            stringUtils.fixedFormat(options.item.bondRule.amount)
          }}</router-link>
      </div>
      <div class="xqbotboxR" v-if="options.isBond == false && options.isMerchantId == 0">
        <a class="a2 sizetype" v-if="options.isHandle == true" @click="showBid">竞价</a>
        <a class="a2 sizetype disabled" v-else>竞价</a>
      </div>
    </div>
  </div>
  <van-popup v-model:show="options.isShow" position="bottom" :style="{ height: '42%' }">
    <div id="size">
      <div class="size1">
        <div class="size1_1">
          <van-image width="70" height="70" style="width: 100%; object-fit: cover" :src="options.goods.goodsPicture" />
        </div>
        <div class="size1_2">
          <p class="p1">当前出价：￥{{ options.nowAmount }}</p>
          <p class="p2">拍品：{{ options.goods.goodsName }}</p>
        </div>
        <div class="size1_3" @click="options.isShow = false">
          <img src="images/close.png" />
        </div>
      </div>

      <div class="size2">
        <van-radio-group v-model="pramsBid.bidNum" @change="changeBidNum">
          <van-cell-group inset>
            <van-cell title="一手" clickable @click="pramsBid.bidNum = '1'">
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
            <van-cell title="两手" clickable @click="pramsBid.bidNum = '2'">
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-cell title="您的出价" :value="'￥' + stringUtils.fixedFormat(pramsBid.amount)" icon="balance-o" />
      </div>
      <div class="size3">
        <a href="javascript:;" @click="send">提交</a>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="options.isRedShow" position="top" :style="{ height: '42%' }">
    <div class="jsyhq pad0">
      <div class="jsyhq_2 bor0" v-for="(red, index) in options.redPacketList" :key="red">
        <a href="javascript:;">
          <div class="jsjfL" style="width: 100%">
            <i style="float: left; width: 5%">{{ index + 1 }}</i>
            <p>
              {{ red.memberName }}￥{{ stringUtils.fixedFormat(red.amount) }}
            </p>
          </div>
          <div class="clear"></div>
        </a>
      </div>
      <div class="kbox"></div>
    </div>
  </van-popup>
  <van-popup v-model:show="options.isVideoShow" position="top"
    :style="{ width: '100%', height: '100vh', opacity: 0.9 }">
    <div style="">
      <span style="
          z-index: 10000;
          position: fixed;
          top: 0;
          right: 0;
          background-color: #fff;
          opacity: 0.8;
        "><img @click="options.isVideoShow = false" src="images/close.png" /></span>

      <video style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
        " autoplay controls>
        <source :src="options.videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </van-popup>
  <van-share-sheet v-model:show="wxOptions.isShowShare" :options="wxOptions.shares" title="立即分享给好友" description="描述信息"
    @select="changeShare" />
  <van-image-preview v-model:show="options.isImgPreviewShow" :startPosition="options.aimgIndex"
    :images="options.aimages" @change="onAChange">
    <template v-slot:index>第{{ options.aimgIndex + 1 }}幅</template>
  </van-image-preview>
</template>

<script>
import detailModel from "@/model/auction/detailModel";
import wxModel from "@/model/wxModel";
import CountDown from "@/components/CountDown.vue";
import ToTop from "@/components/ToTop.vue";
import { onMounted,nextTick } from "vue";
export default {
  components: { CountDown, ToTop },
  name: "auctionDetailPage",
  setup() {
    const {
      options,
      pramsBid,
      bidNum,
      send,
      downScb,
      downEcb,
      showBid,
      searchBid,
      changeBidNum,
      scrollInto,
      payVideo,
      openAPreview,
      onAChange,
      collectionAdd,
    } = detailModel();
    const { wxOptions, initShareConfig, shareMessage, shareTimeline } = wxModel(
      () => { }
    );
    const share = () => {
      wxOptions.isShowShare = true;
    };
    const changeShare = (item, index) => {
      console.log(item);
      console.log(index);

      if (item.icon == "wechat") {
        shareMessage();
      } else if (item.icon == "wechat-moments") {
        shareTimeline();
      }
    };
    onMounted(() => {
      nextTick(()=>{
        initShareConfig({
        title: options.item.title,
        imgUrl: options.imgs[0].pictureMiddle.replace('http://','https://'),
        desc: options.item.synopsis,
        link: decodeURI(window.location.href)
      });
      })
    });

    return {
      wxOptions,
      options,
      pramsBid,
      bidNum,
      send,
      downScb,
      downEcb,
      showBid,
      searchBid,
      changeBidNum,
      scrollInto,
      payVideo,
      share,
      changeShare,
      openAPreview,
      onAChange,
      collectionAdd,
    };
  },
};
</script>

<style>
.am-slider-default .am-control-nav {
  text-align: center;
}

.am-slider-default .am-control-nav li a.am-active {
  background: #cb2527;
}

.am-slider-default .am-control-nav li a {
  border: 0;
  width: 10px;
  height: 10px;
}

.d-amount .d-stock {
  width: 100% !important;
}

.fix-bid li {
  float: left;
  width: 50%;
  text-align: center;
  margin-top: 6rem;
}

.float-item {
  float: left;
  width: 33.33%;
  text-align: center;
  font-size: 14px;
  margin-top: 0.3rem;
}

.fix-bid li a {
  width: 70%;
  margin: 0 auto;
  border-radius: 15px;
  display: block;
  padding: 5px 0;
}

.float-item span {
  margin-top: 0.18rem;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.snap-head {
  width: 100%;
  background: -moz-linear-gradient(top, #ff6666 0%, #ff5967 50%, #ff4165 100%);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #000000),
      color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #ff6666 0%, #ff5967 50%, #ff4165 100%);
  border: 0.02rem solid #ff5967;
  border-radius: 0 0 0.2rem 0.2rem;
  height: 2.8rem;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  z-index: 2000;
}

.snap-head div {
  margin-top: 0.6rem;
  margin-left: 4rem;
  margin-right: 4rem;
}

.snap-head div p {
  font-weight: bold;
  text-align: center;
  color: #9dff00 !important;
}

.snap-head div i {
  color: #ffffff;
}

.xqdp .tjcon {
  padding: 0 0 0.6rem 0;
  margin-top: 1rem;
  margin-left: 0.45rem;
}

.xqdp .tjcon ul {
  overflow: hidden;
  width: 100%;
}

.xqdp .tjcon ul li {
  float: left;
  width: 24%;
  margin-right: 0.2rem;
  position: relative;
}

.xqdp .tjcon ul li img {
  object-fit: cover;
}

.xqdp .tjcon ul li .tit {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
  line-height: 1.5rem;
  background: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
  color: #333;
}
</style>