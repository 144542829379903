<template>
  <div style="width:100%;">
    <span class="title">{{options.title}}</span>
    <van-count-down :time="options.time" v-if="options.isShow">
      <template #default="timeData">
        <span class="block" v-if="timeData.days>0">{{ timeData.days }}</span>
        <span class="colon" v-if="timeData.days>0">天</span>
        <span class="block">{{ timeData.hours }}</span>
        <span class="colon">时</span>
        <span class="block">{{ timeData.minutes }}</span>
        <span class="colon">分</span>
        <span class="block">{{ timeData.seconds }}</span>
        <span class="colon">秒</span>
      </template>
    </van-count-down>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import moment from "moment";
export default {
  name: "vab-count-down",
  props: {
    startTime: new Date(),
    endTime: new Date(),
    prefix:{
      type:String,
      default:''
    }
  },
  setup(props) {
    const options = reactive({
      time: 0,
      isShow: true,
      title:'距'+props.prefix+'开始',
      sTime:new Date(),
      eTime:new Date(),
    });
    function createTime() {
      var now = moment(new Date());
      options.sTime=moment(props.startTime);
      options.eTime=moment(props.endTime);
      console.log("now:"+now+" startTime:"+options.sTime+" endTime:"+options.eTime)
      if (options.sTime > now) {
        options.time = options.sTime - now;
        options.title='距'+props.prefix+'开始:';
      } else if (now >= options.sTime && now <= options.eTime) {
        options.time = options.eTime - now;
        options.title='距'+props.prefix+'结束:';
      } else {
        options.isShow = false;
        options.title='已结束';
      }
      console.log("isShow:"+options.isShow+" title:"+options.title+" time:"+options.time)
    }
    createTime();
    return {
      options,
    };
  },
};
</script>

<style>
.title {
  margin-top: .1rem;
  float: left;
  color: #ee0a24 !important;
  width: 3rem;
  text-align: left;
  text-decoration:dashed !important;
  padding-left:0rem !important;
  line-height: 1rem;
}
.colon {
  display: inline-block;
  margin: 0 4px;
  font-size: .65rem;
  font-weight:bold;
  color: #ee0a24 !important;
  text-decoration:dashed !important;
}
.van-count-down .block {
  display: inline-block;
  width: 1.25rem;
  color: #fff !important;
  font-size: .65rem;
  text-align: center;
  background-color: #ee0a24;
  text-decoration:dashed !important;
}
</style>