<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC0">
        <search-head :isLink="false" @toggleSearch="asearch"></search-head>
      </div>
      <div class="headerR">
        <a href="javascript:;" @click="cancelSearch">取消</a>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="auctionType">
    <a
      href="javascript:;"
      :class="options.searchParams.type == 'DEFAULT' ? 'on' : ''"
      @click="auctionTypeSearch('DEFAULT')"
      >竞拍</a
    >
    <a
      href="javascript:;"
      :class="options.searchParams.type == 'FIXED' ? 'on' : ''"
      @click="auctionTypeSearch('FIXED')"
      >一口价</a
    >
  </div>
  <div class="clear"></div>
  <div class="shopType">
    <ul>
      <li :class="options.nowNab == 'default' ? 'on' : ''" style="width: 5rem">
        <!-- <a href="javascript:;" @click="defaultSearch">综合</a> -->
        <van-dropdown-menu>
          <van-dropdown-item
            @change="orderSearch"
            v-model="value2"
            :options="option2"
          />
        </van-dropdown-menu>
      </li>
      <li
        :class="options.nowNab == 'new' ? 'on' : ''"
        @click="toNewSearch"
        style="width: 5rem"
      >
        <a href="javascript:;">新发布</a>
      </li>
      <li
        :class="options.nowNab == 'today' ? 'on' : ''"
        @click="toDaySearch"
        style="width: 5rem"
      >
        <a href="javascript:;">即将开拍</a>
      </li>
      <li
        :class="options.nowNab == 'finsh' ? 'on' : ''"
        @click="tofinshSearch"
        style="width: 5rem"
      >
        <a href="javascript:;">即将截拍</a>
      </li>
      <li style="float: right; width: 3rem">
        <a href="javascript:;" @click="options.screenShow = true" class="a_sx"
          >筛选</a
        >
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="hbox1"></div>

  <div class="typelevel" v-if="options.searchParams.typeId != ''">
    <div class="typeall">
      <a href="javascript:;" @click="allThree">全部></a>
    </div>
    <div class="typelist">
      <ul v-if="options.child.length > 0">
        <li v-for="c in options.child" :key="c.id">
          <a href="javascript:;" @click="setThree(c.id)">{{ c.name }}</a>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="tolist" @click="changeList"><img src="images/tolist.png" /></div> -->
  <to-top></to-top>
  <div class="kbox"></div>
  <div class="hbox" v-if="options.searchParams.typeId != ''"></div>
  <!-- <div class="likebox" v-if="!options.isList">
    <ul>
      <li v-for="a in options.auctions" :key="a.id">
        <router-link :to="'/auctionDetail/'+a.id">
          <img :src="a.pictureSmall" class="proimg" />
          <p class="tit">{{a.title}}</p>
          <p class="price">
            <vab-count-down :startTime="a.startTime" :endTime="a.endTime"></vab-count-down>
          </p>
        </router-link>
      </li>
    </ul>
  </div> -->
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="shoplist">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <ul>
        <van-list
          v-model:loading="options.loading"
          :finished="options.finished"
          :offset="200"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <li v-for="a in options.auctions" :key="a.id">
            <router-link :to="'/auctionDetail/' + a.id">
              <div class="listL">
                <van-image
                  width="7rem"
                  height="7rem"
                  class="proimg"
                  style="object-fit: cover"
                  :src="a.pictureSmall"
                />
              </div>
              <div class="listR" style="height: 7rem; position: static">
                <div class="v1">
                  {{ a.title }}
                </div>
                <div class="v2">
                  <span v-if="a.isDelayed == 1">延时</span
                  ><span v-if="a.isBond == 1">保证金</span>
                  <span v-if="a.isRed == 1">红包</span>
                  <span
                    v-if="a.isMail == 0"
                    style="color: #4c9108; border: 1px solid rgb(76, 145, 8)"
                    >包邮</span
                  >
                </div>
                <div
                  class="v3"
                  style="
                    margin-bottom: 0;
                    position: absolute;
                    width: 100%;
                    margin-left: 7rem;
                    margin-top: 1rem;
                  "
                >
                  <p class="p1" v-if="a.status != 6" style="width: 100%">
                    <vab-count-down
                      :startTime="a.startTime"
                      :endTime="a.endTime"
                    ></vab-count-down>
                  </p>
                  <p class="p1" v-else>
                    <span class="title" style="width: 100%">已结束</span>
                  </p>
                  <p
                    class="p2"
                    v-if="a.bidCount > 0"
                    style="
                      width: 5rem;
                      margin-top: 0.4rem;
                      margin-left: 11rem;
                      position: absolute;
                    "
                  >
                    {{ a.bidCount }}轮竞拍
                  </p>
                </div>
              </div>
            </router-link>
          </li>
        </van-list>
      </ul>
      <!-- <div>sss</div> -->
    </van-pull-refresh>
  </div>
  <van-popup
    v-model:show="options.screenShow"
    position="right"
    :style="{ height: '100vh', width: '70%' }"
  >
    <div id="sxtj">
      <div class="sx_1">
        筛选<img @click="options.screenShow = false" src="images/close.png" />
      </div>
      <div
        style="
          margin-top: 1.5rem;
          right: 0px;
          height: 85vh;
          position: relative;
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;
          background-color: #fff;
        "
      >
        <div class="sx_2">
          <p class="tit">要求和福利</p>
          <a
            href="javascript:;"
            :class="options.searchParams.isRed == 1 ? 'onCurr' : ''"
            @click="
              options.searchParams.isRed =
                options.searchParams.isRed == 1 ? 0 : 1
            "
            >红包</a
          >
          <a
            href="javascript:;"
            :class="options.searchParams.isBond == 1 ? 'onCurr' : ''"
            @click="
              options.searchParams.isBond =
                options.searchParams.isBond == 1 ? 0 : 1
            "
            >保证金</a
          >
          <a
            href="javascript:;"
            :class="options.searchParams.isDelayed == 1 ? 'onCurr' : ''"
            @click="
              options.searchParams.isDelayed =
                options.searchParams.isDelayed == 1 ? 0 : 1
            "
            >延时</a
          >
        </div>
        <div class="sx_2">
          <p class="tit">开始时间</p>
          <div class="pric">
            <van-field
              v-model="options.searchParams.startTime"
              is-link
              readonly
              name="datetimePicker"
              label="时间选择"
              placeholder="点击选择时间"
              @click="options.showPicker = true"
            />
          </div>
        </div>
        <div class="sx_2">
          <p class="tit">类别</p>
          <div class="tys">
            <van-dropdown-menu>
              <van-dropdown-item
                v-model="options.type"
                :options="options.types"
                :lazy-render="false"
              />
            </van-dropdown-menu>
          </div>
          <a
            href="javascript:;"
            :class="onType(t.id) ? 'onCurr' : ''"
            @click="setType(t)"
            v-for="t in options.second"
            :key="t.id"
            >{{ t.name }}</a
          >
        </div>
        <div
          class="sx_2"
          style="width: 100%"
          v-for="i in options.info"
          :key="i.id"
        >
          <div v-if="i.isAdd == 0">
            <p class="tit">{{ i.key }}</p>
            <a
              href="javascript:;"
              @click="setInfo(i.key, v)"
              :class="onInfo(i.key, v) ? 'onCurr' : ''"
              v-for="v in i.vList"
              :key="v"
              >{{ v }}</a
            >
          </div>
        </div>
      </div>
      <div class="sx_3">
        <a href="javascript:;" @click="screenSearch">确定</a>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="options.showPicker" position="bottom">
    <van-datetime-picker
      type="datetime"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
import { reactive, computed, ref, watch, onMounted } from "vue";
import auctionApi from "@/api/auctionApi";
import goodsInfoApi from "@/api/goodsInfoApi";
import { useStore } from "vuex";
import VabCountDown from "@/components/VabCountDown.vue";
import SearchHead from "@/components/SearchHead.vue";
import stringUtils from "@/utils/stringUtils";
import ToTop from "@/components/ToTop.vue";
export default {
  components: {
    VabCountDown,
    SearchHead,
    ToTop,
  },
  name: "auctionListPage",
  setup() {
    const options = reactive({
      isList: false,
      auctions: [],
      searchParams: {
        draw: 1,
        pageNo: 0,
        length: 10,
        typeId: "",
        type: 0,
        startTime: "",
        toDay: "",
        order: "a",
        goodType: "",
        info: "",
      },
      types: [],
      child: [],
      keys: [],
      screenShow: false,
      showPicker: false,
      nowNab: "default",
      loading: false,
      finished: false,
      refreshing: false,
      pageCount: 1,
      pageNo: 0,
      total: 0,
      type: 14,
      second: [],
      secondTypeId: "",
      info: [],
    });
    function getChildTypes() {
      options.child = [];
      options.types.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((c) => {
            if (c.id == options.searchParams.typeId) {
              options.child = c.children;
            }
          });
        }
      });
      console.log("sssssssssssssssssss" + JSON.stringify(options.child));
    }
    const value2 = ref("a");
    const option2 = [
      { text: "默认排序", value: "a" },
      { text: "价格升序", value: "price_a" },
      { text: "价格降序", value: "price_d" },
      { text: "红包比例升序", value: "red_ratio_a" },
      { text: "红包比例降序", value: "red_ratio_d" },
      // { text: "红包大小升序", value: "red_a" },
      // { text: "红包大小降序", value: "red_d" },
    ];
    const store = useStore();
    options.searchParams = computed(() => store.state.common.searchParams);
    options.types = computed(() => store.state.common.goodTypes);
    watch(
      () => options.types,
      () => {
        getSecondTypes();
      }
    );
    watch(
      () => options.type,
      () => {
        getSecondTypes();
      }
    );
    onMounted(()=>{
      getSecondTypes();
    })
    function setType(item) {
      console.log(item);
      options.keys = [];
      if (options.searchParams.typeId == item.id) {
        options.searchParams.typeId = "";
        options.searchParams.goodType = "";
      } else {
        options.searchParams.typeId = item.id;
        options.searchParams.goodType = getTypeIds(item);
        getSecondTypes();
        getChildTypes();
        getInfoSearch();
      }
    }
    function setInfo(key, val) {
      var iclear = false;
      for (var i = 0; i < options.keys.length; i++) {
        var k = options.keys[i];
        if (k.key == key) {
          if (k.value == val) {
            iclear = true;
          }
          options.keys.splice(i, 1);
        }
      }
      if (!iclear) {
        options.keys.push({ key: key, value: val });
      }
      console.log("keys" + JSON.stringify(options.keys));
    }
    function getSecondTypes() {
      options.child=[];
      options.info=[];
      options.types.forEach((item) => {
        if (item.id == options.type) {
          options.second = item.children;
        }
      });
    }
    function getTypeIds(item) {
      var listIds = [];
      listIds.push(item.id);
      if (item.children != null) {
        for (var el in item.children) {
          listIds.push(item.children[el].id);
        }
      }
      return listIds.join();
    }
    function onType(value) {
      var isAdd = false;
      if (value == options.searchParams.typeId) {
        isAdd = true;
      }
      return isAdd;
    }
    function onInfo(key, value) {
      var isAdd = false;
      options.keys.forEach((k) => {
        if (k.key == key && k.value == value) {
          isAdd = true;
        }
      });
      return isAdd;
    }
    function changeList() {
      options.isList = options.isList ? false : true;
    }
    console.log(JSON.stringify(options.searchParams));
    if (options.searchParams.typeId != "") {
      getChildTypes();
    }

    function setThree(v) {
      options.searchParams.goodType = v;
      options.refreshing = true;
      onRefresh();
    }
    function allThree() {
      var listIds = [];
      listIds.push(options.searchParams.typeId);
      for (var el in options.child) {
        listIds.push(options.child[el].id);
      }
      options.searchParams.goodType = listIds.join();
      options.refreshing = true;
      onRefresh();
    }
    function onConfirm(value) {
      options.searchParams.startTime = stringUtils.dateFormat(
        value,
        "YYYY-MM-DD HH:mm"
      );
      options.showPicker = false;
    }
    async function onRefresh() {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    async function onLoad() {
      console.log("refreshing:" + options.refreshing);
      if (options.refreshing) {
        options.auctions = [];
        options.pageCount = 1;
        options.pageNo = 1;
        options.refreshing = false;
      } else {
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      console.log("pageCount:" + options.pageCount);
      options.searchParams.pageNo = options.pageNo - 1;
      if (options.keys.length > 0) {
        options.searchParams.info = JSON.stringify(options.keys);
      } else {
        options.searchParams.info = "";
      }
      console.log(options.auctions.length + ":" + options.total);
      if (
        options.auctions.length > 0 &&
        options.total > 0 &&
        options.auctions.length >= options.total
      ) {
        options.finished = true;
        options.loading = false;
        return;
      }
      console.log(JSON.stringify(options.searchParams));
      await auctionApi.search(options.searchParams).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(options.total / options.searchParams.length);
          options.pageCount = count;
          res.data.forEach((item) => {
            if (options.auctions.length < res.recordsTotal) {
              options.auctions.push(item);
            }
          });
          options.loading = false;
          if (options.auctions.length == res.recordsTotal) {
            options.finished = true;
          }
        } else {
          options.loading = false;
          options.finished = true;
        }
      });
      // setTimeout(() => {
      //   options.loading = false;
      // },1000)
    }
    function screenSearch() {
      options.screenShow = false;
      onRefresh();
    }
    function auctionTypeSearch(value) {
      options.searchParams.draw = 1;
      options.searchParams.pageNo = 1;
      options.searchParams.length = 10;
      options.searchParams.typeId = "";
      options.searchParams.type = value;
      options.searchParams.startTime = "";
      options.searchParams.toDay = "";
      options.searchParams.order = "a";
      options.searchParams.goodType = "";
      onRefresh();
    }
    function defaultSearch() {
      options.nowNab = "default";
      options.searchParams.type = "";
      options.searchParams.toDay = "";
      options.refreshing = true;
      onRefresh();
    }
    function cancelSearch() {
      options.searchParams.draw = 1;
      options.searchParams.pageNo = 1;
      options.searchParams.length = 10;
      options.searchParams.typeId = "";
      options.searchParams.type = "";
      options.searchParams.startTime = "";
      options.searchParams.toDay = "";
      options.searchParams.order = "a";
      options.searchParams.goodType = "";
      onRefresh();
    }
    function orderSearch() {
      options.searchParams.order = value2;
      options.nowNab = "default";
      options.searchParams.toDay = "";
      onRefresh();
    }
    function toDaySearch() {
      options.nowNab = "today";
      options.searchParams.toDay = "day";
      options.refreshing = true;
      onRefresh();
      options.screenShow = false;
    }
    function tofinshSearch() {
      options.nowNab = "finsh";
      options.searchParams.toDay = "finsh";
      options.refreshing = true;
      onRefresh();
      options.screenShow = false;
    }
    function toNewSearch() {
      options.nowNab = "new";
      options.searchParams.toDay = "new";
      options.refreshing = true;
      onRefresh();
      options.screenShow = false;
    }
    function onCancel() {
      options.searchParams.startTime = "";
      options.showPicker = false;
      options.info=[];
    }
    const getInfoSearch = () => {
      goodsInfoApi
        .getInfoSearch({ productTypeId: options.searchParams.typeId })
        .then((res) => {
          if (res.status == 200) {
            options.info = res.result;
          }
        });
    };
    getChildTypes();
    getSecondTypes();
    //getInfoSearch();
    return {
      options,
      changeList,
      onConfirm,
      screenSearch,
      onCancel,
      toDaySearch,
      defaultSearch,
      toNewSearch,
      tofinshSearch,
      onRefresh,
      onLoad,
      value2,
      option2,
      setType,
      onType,
      setThree,
      allThree,
      auctionTypeSearch,
      cancelSearch,
      orderSearch,
      getInfoSearch,
      setInfo,
      onInfo,
    };
  },
};
</script>
<style>
/* .shoplist{
  height: 90vh;
} */
#sxtj {
  position: relative;
  right: 0;
  width: 100%;
  height: 100vh;
}
.van-field__body input {
  width: 100% !important;
  text-align: center !important;
  color: #000 !important;
}
.van-cell {
  padding: 0 0 0 0;
}
.onCurr {
  color: #fff !important;
  background: #ff2150 !important;
}
.shopType {
  top: 5.2rem !important;
  z-index: 2001;
}
.sx_1 img {
  float: right;
  width: 1rem;
  margin-top: 0.5rem;
}
.van-cell {
  padding: 0 0 0 0.1rem !important;
}
.van-dropdown-menu__bar {
  box-shadow: none !important;
  height: 2.4rem !important;
}
.auctionType {
  position: fixed;
  background-color: #fff;
  height: 1.8rem;
  border-width: 0;
  z-index: 2000;
  top: 2.8rem;
  width: 100%;
}
.auctionType a {
  float: left;
  width: 50%;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.8rem;
}
.auctionType .on {
  border-bottom: 1px solid #ff2150;
  box-sizing: border-box;
}
.v2 span {
  width: 50%;
  margin-left: 0.1rem;
}
</style>