<template>
  <van-swipe v-if="options.imgs.length>0" :autoplay="3000" lazy-render :style="'height:'+height+'vh'">
        <van-swipe-item v-for="image in options.imgs" :key="image">
        <a v-if="image.url!=''" :href="image.url"><img :src="image.path" style="width: 100%" /></a>
          <img v-else :src="image.path" style="width: 100%" />
        </van-swipe-item>
      </van-swipe>
</template>

<script>
import { reactive,computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
export default {
    props:{
        code:{
            type:String,
            default:''
        },
        height:{
            type:Number,
            default:48
        }
    },
    setup(props){
        const options=reactive({
            imgs:[],
            all:[],
        });
        const store = useStore();
        options.all=computed(() => store.state.cms.ads)
        const getAdCode=()=>{
            options.imgs=[];
             if( options.all.length>0){
               for(var el in  options.all){
                    if(options.all[el].code==props.code){
                        options.imgs.push(options.all[el]);
                    }
                }
            }
            //console.log("ad:"+JSON.stringify(options.imgs));
        }
        watch(()=>options.all,()=>{
            getAdCode();
        });
        onMounted(()=>{
             getAdCode();
        })
        getAdCode();
        return {
            options
        }
    }
}
</script>

<style>

</style>