<template>
   <div class="ssdiv">
        <input name="" type="text" v-model="options.searchParams.title" placeholder=""/>
        <a href="javascript:;" @click="search"><img src="images/ss.png" /></a>
      </div>
</template>

<script>
import { reactive,computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
    props:{
        isLink:{
            type:Boolean,
            default:false
        },
    },
    emits: ["toggleSearch"],
    setup(props,context){
        const options=reactive({
            searchParams:{
            draw: 1,
            start: 0,
            length: 10,
            title:''
        }
        });
        const store = useStore();
        const router= useRouter();
        options.searchParams=computed(() => store.state.common.searchParams)
        function search(){
            store.commit('setSearchParams',options.searchParams);
            if(props.isLink){
                 router.push({path:'/auctionList'});
            }else{
                context.emit('toggleSearch')
            }
             
        }
        return {
            options,
            search
        }
    }
}
</script>

<style>

</style>