<template>
<div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC0">
      <search-head :isLink="true"></search-head>
    </div>
    <div class="headerR">
      <a href="javascript:;" @click="clearSearch">取消</a>
    </div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="shoptypebox">
  <div class="shtypeLeft" style="margin-bottom: 0.5rem;
        right: 0px;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #fff;">
    <ul>
      <li :class="options.oneId==one.id?'on':''" v-for="one in options.types" :key="one.id"><a href="javascript:;"  @click="setOne(one)">{{one.label}}</a></li>
    </ul>
  </div>
  <div class="shtypeRight">
    <div class="boxOne">
      <div class="box1">
        <ad-img code="AuctionTypeAd" height="10"></ad-img>
      </div>
      <a href="javascript:;" >
      <div class="box2 botrtop">
        <p>{{options.rightItem.label}}</p>
      </div>
      </a>
      <div class="box3" style="margin-bottom: 0.5rem;
        right: 0px;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        background-color: #fff;">
        <ul >
          <li v-for="c in options.rightItem.children" :key="c.id">
            <a href="javascript:;" @click="toSearch(c)">
            <img :src="c.picture" />
            <p>{{c.label}}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>
</template>

<script>
import { reactive,computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SearchHead from '@/components/SearchHead.vue';
import AdImg from '@/components/AdImg.vue';
export default {
  components: { 
    SearchHead,
    AdImg 
    },
    name:'goodsTypePage',
    setup(){
       const options=reactive({
         types:[],
         oneId:0,
         rightItem:{
           label:'',
           children:[]
         },
         searchParams:{
            draw: 1,
            start: 0,
            length: 10,
            goodType:'',
            typeId:''
        }
       });
       const store = useStore();
       const router= useRouter();
       options.types=computed(() => store.state.common.goodTypes);
       onMounted(()=>{
         if(options.types.length>0){
           options.oneId=options.types[0].id;
           options.rightItem=options.types[0];
         }
       })
       watch(()=>options.types,()=>{
         if(options.types.length>0){
           options.oneId=options.types[0].id;
           options.rightItem=options.types[0];
         }
       })
       function setOne(item){
         options.oneId=item.id;
         options.rightItem=item;
       }
       function toSearch(item){
         options.searchParams.goodType=getTypeIds(item);
         options.searchParams.typeId=item.id;
         store.commit('setSearchParams',options.searchParams);
         router.push({path:'/auctionList'});
       }
       function clearSearch(){
         options.searchParams.goodType='';
          options.searchParams.typeId='';
          store.commit('setSearchParams',options.searchParams);
         router.push({path:'/auctionList'});
       }
       function getTypeIds(item){
         var listIds=[];
         listIds.push(item.id);
         if(item.children!=null){
            for(var el in item.children){
              listIds.push(item.children[el].id);
            }
         }
         return listIds.join();
       }
       return {
         options,
         setOne,
         toSearch,
         clearSearch
       }
    }
}
</script>

<style>
.box3 ul{
  min-height: 70vh;
}
</style>