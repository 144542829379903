<template>
  <div>
    <p v-if="options.msTime.show">
      <span v-if="options.tipShow">{{ tipText }}:</span>
      <span v-if="!options.tipShow">{{ options.tipTextEnd }}:</span>
      <span v-if="options.msTime.day > 0"
        ><span>{{ options.msTime.day }}</span
        ><i>{{ dayTxt }}</i></span
      >
      <span v-if="options.msTime.hour > 0"
        >{{ options.msTime.hour }}<i>{{ hourTxt }}</i></span
      >
      <span>{{ options.msTime.minutes }}</span
      ><i>{{ minutesTxt }}</i> <span>{{ options.msTime.seconds }}</span
      ><i>{{ secondsTxt }}</i>
    </p>
    <p v-if="!options.msTime.show">{{ endText }}</p>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { onMounted, watch, onBeforeUnmount } from "@vue/runtime-core";
export default {
  replace: true,
  props: {
    //距离开始提示文字
    tipText: {
      type: String,
      default: "距离开始",
    },
    //距离结束提示文字
    tipTextEnd: {
      type: String,
      default: "距离结束",
    },
    //时间控件ID
    id: {
      type: String,
      default: "1",
    },
    //当前时间
    currentTime: {
      type: Number,
    },
    // 活动开始时间
    startTime: {
      type: Number,
    },
    // 活动结束时间
    endTime: {
      type: Number,
    },
    // 延时时间
    delayedTime: {
      type: Number,
      default: 0,
    },
    // 延时结束
    delayedEnd: {
      type: Boolean,
      default: true,
    },
    // 倒计时结束显示文本
    endText: {
      type: String,
      default: "已结束",
    },
    //自定义显示文字:天
    dayTxt: {
      type: String,
      default: ":",
    },
    //自定义显示文字:时
    hourTxt: {
      type: String,
      default: ":",
    },
    //自定义显示文字:分
    minutesTxt: {
      type: String,
      default: ":",
    },
    secondsTxt: {
      type: String,
      default: ":",
    },
    //是否开启秒表倒计，未完成
    secondsFixed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let timeOut;
    const options = reactive({
      tipShow: true,
      msTime: {
        //倒计时数值
        show: false, //倒计时状态
        day: "", //天
        hour: "", //小时
        minutes: "", //分钟
        seconds: "", //秒
      },
      star: "", //活动开始时间
      end: "", //活动结束时间
      current: "", //当前时间
      startNum: 0,
      tipTextEnd: props.tipTextEnd,
    });
    function gogogo() {
      //判断是秒还是毫秒
      if (timeOut != "") {
        clearTimeout(timeOut);
      }
      props.startTime.toString().length == 10
        ? (options.star = props.startTime * 1000)
        : (options.star = props.startTime);
      props.endTime.toString().length == 10
        ? (options.end = props.endTime * 1000)
        : (options.end = props.endTime);
      if (props.currentTime) {
        props.currentTime.toString().length == 10
          ? (options.current = props.currentTime * 1000)
          : (options.current = props.currentTime);
      } else {
        options.current = new Date().getTime();
      }

      if (options.end < options.current) {
        /**
         * 结束时间小于当前时间 活动已结束
         */
        // if (props.delayedTime > 0 && props.delayedEnd) {
        //   options.end = options.end + props.delayedTime * 1000;
        //   options.tipShow = false;
        //   options.msTime.show = true;
        //   timeOut = setTimeout(() => {
        //     runTime(options.end, options.current, end_message, true);
        //   }, 1);
        // } else {
          options.msTime.show = false;
          end_message();
        // }
      } else if (options.current < options.star) {
        /**
         * 当前时间小于开始时间 活动尚未开始
         */
        //this.$set(this, "tipShow", true);
        options.tipShow = true;
        timeOut = setTimeout(() => {
          runTime(options.star, options.current, start_message);
        }, 1);
      } else if (
        (options.end > options.current && options.star < options.current) ||
        options.star == options.current
      ) {
        /**
         * 结束时间大于当前并且开始时间小于当前时间，执行活动开始倒计时
         */
        //this.$set(this, "tipShow", false);
        options.startNum = options.startNum + 1;
        console.log(
          "end:" +
            options.end +
            " current:" +
            options.current +
            " start:" +
            options.star
        );
        options.tipShow = false;
        options.msTime.show = true;
        timeOut = setTimeout(() => {
          runTime(options.end, options.star, end_message, true);
        }, 1);
        if (options.startNum == 1) {
          context.emit("start_callback", options.msTime.show);
        }
      }
    }
    onMounted(() => {
      gogogo();
    });
    watch(
      () => props.currentTime,
      () => {
        gogogo();
      }
    );
    watch(
      () => props.endTime,
      () => {
          if (timeOut != "") {
            clearTimeout(timeOut);
          }
          gogogo();
      }
    );
    onBeforeUnmount(() => {
      if (timeOut != "") {
        clearTimeout(timeOut);
      }
    });
    function runTime(startTime, endTime, callFun, type) {
      if (timeOut != "") {
        clearTimeout(timeOut);
      }
      let timeDistance = startTime - endTime;
      console.log("timeDistance:" + timeDistance);
      if (timeDistance > 0) {
        options.msTime.show = true;
        console.log("时间差:" + timeDistance);
        options.msTime.day = Math.floor(timeDistance / 86400000);
        console.log("天数:" + options.msTime.day);
        timeDistance -= options.msTime.day * 86400000;
        options.msTime.hour = Math.floor(timeDistance / 3600000);
        timeDistance -= options.msTime.hour * 3600000;
        options.msTime.minutes = Math.floor(timeDistance / 60000);
        timeDistance -= options.msTime.minutes * 60000;
        //是否开启秒表倒计,未完成
        //                    this.secondsFixed ? msTime.seconds = new Number(timeDistance / 1000).toFixed(2) : msTime.seconds = Math.floor( timeDistance / 1000 ).toFixed(0);
        options.msTime.seconds = Math.floor(timeDistance / 1000).toFixed(0);
        timeDistance -= options.msTime.seconds * 1000;

        if (options.msTime.hour < 10) {
          options.msTime.hour = "0" + options.msTime.hour;
        }
        if (options.msTime.minutes < 10) {
          options.msTime.minutes = "0" + options.msTime.minutes;
        }
        if (options.msTime.seconds < 10) {
          options.msTime.seconds = "0" + options.msTime.seconds;
        }
        let _s = Date.now();
        let _e = Date.now();
        let diffPerFunc = _e - _s;
        timeOut = setTimeout(() => {
          if (type) {
            runTime(options.end, (endTime += 1000), callFun, true);
          } else {
            runTime(options.star, (endTime += 1000), callFun, false);
          }
        }, 1000 - diffPerFunc);
      } else {
        callFun();
      }
    }
    function start_message() {
      //this.$set(this, "tipShow", false);
      console.log("start_callback:" + options.msTime.show);
      console.log("start down");
      options.tipShow = false;
      options.msTime.show = true;
      context.emit("start_callback", options.msTime.show);
      timeOut = setTimeout(() => {
        runTime(options.end, options.star, end_message, true);
      }, 1);
    }
    function end_message() {
      options.msTime.show = false;
      console.log("end currentTime:" + props.currentTime);
      if (props.currentTime <= 0) {
        return;
      }
      if (props.delayedTime > 0 && props.delayedEnd) {
        options.end = options.end + props.delayedTime * 1000;
        console.log("延时:" + options.end);
        options.tipTextEnd = "延时结束";
        if (timeOut != "") {
          clearTimeout(timeOut);
        }
        timeOut = setTimeout(() => {
          runTime(options.end, new Date().getTime(), end_message, true);
        }, 1);
      } else {
        context.emit("end_callback", options.msTime.show);
      }
    }
    return {
      options,
      gogogo,
      start_message,
    };
  },
};
</script>
